import { TiktokEvent } from '../models/tiktok-event.model';
import { TiktokEventBody } from '../models/tiktok-event-body.model';
import { EventProductTiktok } from '../models/event-product.model';
import { TiktokEventType } from '../models/tiktok-event-type.model';

export class AddToCartTiktokEvent implements TiktokEvent {
    public eventType: TiktokEventType;
    // public currency = '';
    // public value = 0;
    private product: EventProductTiktok;

    constructor(product: EventProductTiktok) {
        this.eventType = 'AddToCart';
        this.product = product;
    }

    getEvent(): TiktokEventBody {
        return {
            value: this.product.price,
            currency: this.product.currency,
            contents: [{
                content_id: `${this.product.id}`,
                content_type: this.product.type,
                content_name: this.product.name,
            }],
        };
    }
}
