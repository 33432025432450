import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isNil } from '@citydeals/common';
import { TiktokEvent } from './models/tiktok-event.model';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class TiktokTrackService {
    private run: boolean;

    constructor(@Inject(PLATFORM_ID) platformId: string) {
        this.run = isPlatformBrowser(platformId);
    }

    track(event: TiktokEvent) {
        if (this.run === false) {
            return;
        }
        // const ttq = (window as { ttq?: unknown[] }).ttq;
        // if (isNil(ttq)) {
        //     console.error('The Tiktok Tag Manager is not registered');
        //     return;
        // }
        let ttq = undefined;
        if (typeof (window as any).ttq !== 'undefined') {
            ttq = (window as any).ttq;
        } else {
            console.error('TikTok Pixel (ttq) is not available.');
        }
        console.log('event: ', event);
        console.log('ttq: ', ttq);
        //console.log('window ttq: ', window.ttq);
        //ttq.track(event.getEvent());
        ttq.track(event.eventType, event.getEvent())
    }
}
